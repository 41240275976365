<template>
  <b-input-group v-if="shopList && shopList.length !== 0">
    <el-select
      class="form-select-solid"
      placeholder="Select Shop"
      v-model="shop"
      :loading="shopListIsLoading"
    >
      <el-option v-for="(item, index) in dropdownOptions"
                 :label="item.shop_name"
                 :value="item.id"
                 :key="`shop-option-${index}`"
      ></el-option>
    </el-select>
<!--    <b-form-select
      class="form-select-solid"
      placeholder="Select Shop"
      v-model="shop"
      :loading="shopListIsLoading"
    >
      <b-form-select-option v-for="(item, index) in dropdownOptions"
                            :label="item.shop_name"
                            :value="item.id"
                            :key="`shop-option-${index}`"
      >Please select an option
      </b-form-select-option>
    </b-form-select>-->
  </b-input-group>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "ShopSelect",

  computed: {
    ...mapGetters({
      shopList: 'shopify/shops/list',
      shopListIsLoading: 'shopify/shops/listIsFetching',

      selectedShop: 'shopify/shops/selected'
    }),

    dropdownOptions(): Record<string, unknown>[] {
      if(this.shopListIsLoading) return [];
      return this.shopList;
    },

    shop:  {
      get(): string {
        return this.selectedShop?.id;
      },
      set(id) {
        if(!this.shopList) return;

        let shop = null;
        if(id) shop = this.shopList.find((shop) => shop.id === id);

        this.selectShop(shop);
      }
    }
  },

  methods: {
    ...mapActions({
      fetchShopList: 'shopify/shops/fetch',
    }),
    ...mapMutations({
      selectShop: 'shopify/shops/select'
    }),
  },

  mounted() {
    this.fetchShopList({force: false});
  }
});
</script>

<style scoped>
.el-select {width: 100%;}
.el-select :deep(.el-input__inner) {
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  color: white;
}
</style>