<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded rounded-bottom-left-0 menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <!--begin::Menu item: User name, email -->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <img v-if="user.avatar" :src="user.avatar"/>
          <img v-else src="/media/avatars/blank.png"/>
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column min-vw-0">
          <div class="fw-bolder d-flex align-items-center text-nowrap fs-5 overflow-scroll-hidden">
            {{ user.first_name +' '+ user.last_name }}
          </div>
          <div class="fw-bold text-muted text-nowrap fs-7 overflow-scroll-hidden">
            {{ user.email }}
          </div>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item: language -->
    <div class="menu-item pe-5"
         v-if="canChangeLanguage"
         data-kt-menu-trigger="hover"
         data-kt-menu-placement="right-start"
         data-kt-menu-flip="right">
      <router-link to="/pages/profile/overview" class="menu-link px-5">
        <span class="menu-title position-relative">
          Language
          <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {{ currentLanguageLocale.name }}
            <img class="w-15px h-15px rounded-1 ms-2" :src="'/'+currentLanguageLocale.flag"/>
          </span>
        </span>
      </router-link>

      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click.prevent="setCurrentLanguage('en')"
            href="javascript:void(0)"
            class="menu-link d-flex px-5"
            :class="{ active: isCurrentLanguage('en') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                src="/media/svg/flags/226-united-states.svg"
                alt="metronic"
              />
            </span>
            English
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click.prevent="setCurrentLanguage('de')"
            href="javascript:void(0)"
            class="menu-link d-flex px-5"
            :class="{ active: isCurrentLanguage('de') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                src="/media/svg/flags/162-germany.svg"
                alt="metronic"
              />
            </span>
            German
          </a>
        </div>
        <!-- end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <template v-if="canAccessSaveChangesPages">

      <!--begin::Menu item: Manage Profile -->
      <div class="menu-item">
        <router-link :to="{name: 'profile.settings'}"
                     class="menu-link px-5 rounded-0"
                     active-class="active">
          <span class="menu-icon">
            <i class="fas fa-user fs-3"></i>
          </span>
          <span class="menu-text">Manage Profile</span>
        </router-link>
      </div>
      <!--end::Menu item-->

      <!--begin::Menu separator-->
      <div class="separator my-2"></div>
      <!--end::Menu separator-->

      <!--begin::Menu item: Shopify -->
      <div class="menu-item">
        <router-link :to="{name: 'integration.shopify'}"
                     class="menu-link px-5 rounded-0"
                     active-class="active">
          <span class="menu-icon">
            <i class="fab fa-shopify fs-3"></i>
          </span>
          <span class="menu-text">Shopify</span>
        </router-link>
      </div>
      <!--end::Menu item-->


      <!--begin::Menu item: Facebook -->
      <div class="menu-item">
        <router-link :to="{name: 'integration.facebook'}"
                     class="menu-link px-5 rounded-0"
                     active-class="active">
          <span class="menu-icon">
            <i class="bi-facebook bi fs-3"></i>
          </span>
          <span class="menu-text">Facebook</span>
        </router-link>
      </div>
      <!--end::Menu item-->

      <!--begin::Menu item: Google -->
      <div class="menu-item" v-if="false">
        <router-link :to="{name: 'integration.google.accounts'}"
                     class="menu-link px-5 rounded-0"
                     active-class="active">
          <span class="menu-icon">
            <i class="bi-google bi fs-3"></i>
          </span>
          <span class="menu-text">Google</span>
        </router-link>
      </div>
      <!--end::Menu item-->

      <!--begin::Menu separator-->
      <div class="separator my-2"></div>
      <!--end::Menu separator-->

    </template>

    <!--begin::Menu item: Sign Out -->
    <div class="menu-item">
      <a @click="signOut()" class="menu-link px-5 rounded-0">
        <span class="menu-icon">
          <i class="fas fa-sign-out-alt fs-3"></i>
        </span>
        <span class="menu-text">Sign Out</span>
      </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import {mapActions, mapGetters, useStore} from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

const languageLocales = {
  en: {
    flag: "media/svg/flags/226-united-states.svg",
    name: "English"
  },
  de: {
    flag: "media/svg/flags/162-germany.svg",
    name: "German"
  },
};

export default defineComponent({
  name: "kt-user-menu",
  components: {},

  data() {
    return {
      canChangeLanguage: false,
    }
  },

  computed: {
    ...mapGetters({
      user: 'currentUser',
      // TODO: update after AuthModule is refactored
      userSettings: 'getUserSettings',
    }),

    canAccessSaveChangesPages(): boolean {
      return this?.userSettings?.access_frontend_save_changes ?? true;
    },

    currentLanguageLocale() {
      return languageLocales[this.$i18n.locale];
    }
  },

  methods: {
    ...mapActions({
      authLogout: Actions.LOGOUT,
    }),

    signOut() {
      this.authLogout();
      this.$router.push({ name: "sign-in" });
    },

    isCurrentLanguage(lang: string) {
      return this.$i18n.locale === lang
    },

    setCurrentLanguage(lang: string) {
      this.$i18n.locale = lang;
    }
  },
});
</script>