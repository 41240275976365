
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {useRoute, RouterLink} from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { userCanAccessRoute } from "@/router/guards/AuthGuard";

export default defineComponent({
  name: "kt-menu",
  components: {
    RouterLink,
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    const isVisibleMenuItem = routeName => {
      return userCanAccessRoute(routeName);
    }

    const routerLinkTo = menuItem => {
      let result: null|Record<string, unknown> = null;
      if(menuItem.path) result = {path: menuItem.path};
      if(menuItem.route) result = {name: menuItem.route};
      return result ?? '/';
    };

    return {
      hasActiveChildren,
      routerLinkTo,
      isVisibleMenuItem,
      MainMenuConfig,
      asideMenuIcons,
      translate
    };
  }
});
