<template>
  <!--begin::Aside-->
  <div
    id="kt_aside"
    class="aside aside-hoverable"
    :class="[
      asideTheme === 'light' && 'aside-light',
      asideTheme === 'dark' && 'aside-dark'
    ]"
    data-kt-drawer="true"
    data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
  >
    <!--begin::Brand-->
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <!--begin::Logo-->
      <a href="/" v-if="asideTheme === 'dark'">
        <img alt="Logo" :src="darkLogo" class="h-25px logo" />
      </a>
      <a href="/" v-if="asideTheme === 'light'">
        <img alt="Logo" :src="lightLogo" class="h-25px logo" />
      </a>
      <!--end::Logo-->

      <!--begin::Aside toggler-->
      <div
        id="kt_aside_toggle"
        class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
      >
        <span class="svg-icon svg-icon-1 rotate-180">
          <inline-svg title="toggle" src="/media/svg/icons/Navigation/Angle-double-left.svg"/>
        </span>
      </div>
      <!--end::Aside toggler-->
    </div>
    <!--end::Brand-->

    <div class="menu-item">
      <div class="menu-content">
        <shop-select />
      </div>
    </div>

    <div class="menu-item">
      <div class="menu-content">
        <div class="separator mx-1 my-4"></div>
      </div>
    </div>

    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
      <KTMenu></KTMenu>
    </div>
    <!--end::Aside menu-->

    <!--begin::Footer-->
    <div class="aside-footer flex-column-auto pt-5 pb-7 ps-4 pe-7" id="kt_aside_footer">
      <!--begin::User-->
      <div class="d-flex align-items-center aside-user-menu" id="kt_header_user_menu_toggle">
        <!--begin::Menu-->
        <div
          class="cursor-pointer d-flex min-vw-0 w-100"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="top-start"
          data-kt-menu-flip="top"
        >
          <!--begin::Avatar-->
          <div class="symbol symbol-30px symbol-md-40px flex-column">
            <img v-if="currentUser.avatar" :src="currentUser.avatar"/>
            <img v-else src="/media/avatars/blank.png"/>
          </div>
          <!--end::Avatar-->

          <!--begin::Username-->
          <div class="d-flex flex-row ps-2 username text-nowrap min-vw-0">
            <div class="fw-bolder d-flex align-items-center fs-5 overflow-scroll-hidden">
              {{ currentUser.first_name +' '+ currentUser.last_name }}
              <!--            <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span>-->
            </div>
          </div>
          <!--end::Username-->
        </div>

        <KTUserMenu />
        <!--end::Menu-->
      </div>
      <!--end::User -->
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Aside-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import { asideTheme } from "@/core/helpers/config";
import ShopSelect from "@/layout/aside/ShopSelect.vue";
import KTMenu from "@/layout/aside/Menu.vue";
import KTUserMenu from "@/layout/aside/UserMenu.vue";


export default defineComponent({
  name: "KTAside",
  components: {
    ShopSelect,
    KTMenu,
    KTUserMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    onMounted(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
      t,
      currentUser: store.getters.currentUser,
    };
  }
});
</script>

<style scoped>

</style>