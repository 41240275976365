
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import { asideTheme } from "@/core/helpers/config";
import ShopSelect from "@/layout/aside/ShopSelect.vue";
import KTMenu from "@/layout/aside/Menu.vue";
import KTUserMenu from "@/layout/aside/UserMenu.vue";


export default defineComponent({
  name: "KTAside",
  components: {
    ShopSelect,
    KTMenu,
    KTUserMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    onMounted(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
      t,
      currentUser: store.getters.currentUser,
    };
  }
});
