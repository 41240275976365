
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import {mapActions, mapGetters, useStore} from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

const languageLocales = {
  en: {
    flag: "media/svg/flags/226-united-states.svg",
    name: "English"
  },
  de: {
    flag: "media/svg/flags/162-germany.svg",
    name: "German"
  },
};

export default defineComponent({
  name: "kt-user-menu",
  components: {},

  data() {
    return {
      canChangeLanguage: false,
    }
  },

  computed: {
    ...mapGetters({
      user: 'currentUser',
      // TODO: update after AuthModule is refactored
      userSettings: 'getUserSettings',
    }),

    canAccessSaveChangesPages(): boolean {
      return this?.userSettings?.access_frontend_save_changes ?? true;
    },

    currentLanguageLocale() {
      return languageLocales[this.$i18n.locale];
    }
  },

  methods: {
    ...mapActions({
      authLogout: Actions.LOGOUT,
    }),

    signOut() {
      this.authLogout();
      this.$router.push({ name: "sign-in" });
    },

    isCurrentLanguage(lang: string) {
      return this.$i18n.locale === lang
    },

    setCurrentLanguage(lang: string) {
      this.$i18n.locale = lang;
    }
  },
});
