
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "ShopSelect",

  computed: {
    ...mapGetters({
      shopList: 'shopify/shops/list',
      shopListIsLoading: 'shopify/shops/listIsFetching',

      selectedShop: 'shopify/shops/selected'
    }),

    dropdownOptions(): Record<string, unknown>[] {
      if(this.shopListIsLoading) return [];
      return this.shopList;
    },

    shop:  {
      get(): string {
        return this.selectedShop?.id;
      },
      set(id) {
        if(!this.shopList) return;

        let shop = null;
        if(id) shop = this.shopList.find((shop) => shop.id === id);

        this.selectShop(shop);
      }
    }
  },

  methods: {
    ...mapActions({
      fetchShopList: 'shopify/shops/fetch',
    }),
    ...mapMutations({
      selectShop: 'shopify/shops/select'
    }),
  },

  mounted() {
    this.fetchShopList({force: false});
  }
});
