const DocMenuConfig: Record<any, any> = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "dashboard",
        // svgIcon: "/media/svg/icons/Design/PenAndRuller.svg",
        svgIcon: null,
        fontIcon: "bi-kanban-fill"
      },
      {
        heading: "facebookStats",
        route: "stats.facebook",
        svgIcon: null,
        fontIcon: "bi-bar-chart",
      },
      // {
      //   sectionTitle: "integrations",
      //   route: "integrations",
      //   svgIcon: null,
      //   fontIcon: "bi-cpu-fill",
      //   sub: [
      //     {
      //       heading: "facebook",
      //       route: "integration.facebook",
      //       svgIcon: null,
      //       fontIcon: "bi-facebook",
      //     },
      //     {
      //       heading: "shopify",
      //       route: "integration.shopify",
      //       svgIcon: null,
      //       fontIcon: "bi-basket3-fill",
      //     },
      //   ]
      // },
      // {
      //   heading: "campaigns",
      //   route: "campaigns",
      //   svgIcon: null,
      //   fontIcon: "bi-megaphone-fill"
      // },
      {
        heading: "actionLogs",
        route: "automation.actionLogs",
        // svgIcon: '/media/icons/duotune/coding/cod002.svg',
        svgIcon: null,
        fontIcon: "bi-journal-text"
      },
      // {
      //   heading: "strategies",
      //   route: "strategies",
      //   svgIcon: '/media/icons/duotune/ecommerce/ecm009.svg',
      //   fontIcon: "bi-sliders"
      // },
      {
        heading: "settings",
        route: "settings",
        // svgIcon: "/media/icons/duotune/coding/cod001.svg",
        svgIcon: null,
        fontIcon: "bi-gear-fill"
      },
    ]
  },
];

export default DocMenuConfig;
